import axiosInstance from '../util/axios-instance';

// Login function
export const login = async (username, password) => {
  try {
    const response = await axiosInstance.post(`/api/auth/login`, {
      username,
      password,
    });
    return response.data; // contains access_token, refresh_token, etc.
  } catch (error) {
    console.error('Login failed:', error);
  }
};

// Refresh token function
export const refreshToken = async (refreshToken) => {
  try {
    const response = await axiosInstance.post(`/api/auth/refresh`, {
      token: refreshToken,
    });
    return response.data; // contains new access_token, refresh_token
  } catch (error) {
    console.error('Token refresh failed:', error);
  }
};

export const getConfig = async () => {
  try {
    const response = await axiosInstance.get('/api/config');
    return response.data; // this will contain { "gameCodes": ["999999"] }
  } catch (error) {
    console.error('Error fetching config:', error);
  }
};

export const getMe = async () => {
  try {
    const response = await axiosInstance.get('/api/me/info');
    return response.data; // this will contain { "gameCodes": ["999999"] }
  } catch (error) {
    console.error('Error fetching config:', error);
  }
};
