import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/auth-context';

// Mock auth function - replace with real authentication logic

const PrivateRoute = ({ children }) => {
  const auth = useAuth();

  if (!auth?.user) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
