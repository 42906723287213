import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, Checkbox, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../../../theme/theme';
import { prepareCustomerSurvey } from '../../../service/customer-service';
import { getGifts } from '../../../service/contact-service';

const SelectContactPage = ({ setActiveStep, formData, setSelectedPeople, addManualContact }) => {
  const navigate = useNavigate();
  const [checkedPeople, setCheckedPeople] = useState({});
  const [surveyData, setSurveyData] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(''); // Error state
  const [gifts, setGifts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const giftsData = await getGifts();
      if (giftsData && giftsData.data) {
        setGifts(giftsData.data);
      }
    };
    fetchData();
  }, []);

  const handleCheck = (group, personIndex) => {
    const key = `${group}-${personIndex}`;
    setCheckedPeople((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'HIGH':
        return theme.palette.component.good;
      case 'LOW':
        return theme.palette.component.improve;
      case 'MEDIUM':
        return theme.palette.component.medium;
      default:
        return theme.palette.component.default;
    }
  };

  // Function to get the gift icon based on the gift id
  const getGiftIcon = (giftId) => {
    // Find the gift data by its id
    const gift = gifts.find((g) => g.id === giftId);
    if (gift) {
      switch (gift.iconName) {
        case 'apple':
          return '🍎';
        case 'banana':
          return '🍌';
        case 'orange':
          return '🍊';
        case 'grape':
          return '🍇';
        default:
          return '🎁'; // Default if iconName is not recognized
      }
    }
    return '🎁'; // Default if gift is not found
  };

  const handleConfirmSelection = () => {
    const selected = Object.keys(checkedPeople).filter((key) => checkedPeople[key]);

    // Map selected items to their respective people data
    const selectedPeopleList = selected
      .map((key) => {
        const [group, personIndex] = key.split('-');
        const person =
          group === 'customerGroup'
            ? surveyData?.data?.customerGroup?.customers?.[personIndex]
            : group === '__lastNameGroup'
            ? surveyData?.data?.__lastNameGroup?.[personIndex]
            : addManualContact?.[personIndex];
        return person || null; // Return the full person object
      })
      .filter(Boolean);

    // Merge selected people and manually added contacts
    const mergedPeople = [
      ...selectedPeopleList, // From selected checkboxes
      ...addManualContact, // From manually added contacts
    ];

    // Ensure the user's own data is included but only once
    const userEntry = {
      id: surveyData?.data?.id || '',
      title: surveyData?.data?.title || '',
      firstName: surveyData?.data?.firstName || '',
      lastName: surveyData?.data?.lastName || '',
      displayName: surveyData?.data?.displayName || '',
      nationalId: surveyData?.data?.nationalId || '',
      address: surveyData?.data?.address || '',
      zoneL1: surveyData?.data?.zoneL1 || '',
      zoneL2: surveyData?.data?.zoneL2 || '',
      zoneL3: surveyData?.data?.zoneL3 || '',
      zoneL4: surveyData?.data?.zoneL4 || '',
      giftMeta: surveyData?.data?.giftMeta || [],
      giftTotalValue: surveyData?.data?.giftTotalValue || 0,
      surveyResult: surveyData?.data?.surveyResult || '',
      lastSurveyDateTime: surveyData?.data?.lastSurveyDateTime || null,
    };

    // Check if the userEntry is already in the merged list
    const alreadyContainsUser = mergedPeople.some((person) => person.id === userEntry.id);

    // Only add the userEntry if it's not already in the list
    if (!alreadyContainsUser) {
      mergedPeople.unshift(userEntry); // Add the userEntry at the start of the list
    }

    // Deduplicate using Map while checking for existing IDs
    const deduplicatedPeople = Array.from(
      new Map(
        mergedPeople.map((person) => [
          person.id,
          { ...person }, // Make a shallow copy to avoid mutation issues
        ])
      ).values()
    );

    // Debugging: Log after deduplication
    console.log('Deduplicated people:', deduplicatedPeople);

    // Final selected people list
    const finalSelectedPeopleList = deduplicatedPeople;

    // Debugging: Log final list
    console.log('Final selected people list:', finalSelectedPeopleList);

    setSelectedPeople(finalSelectedPeopleList);
    setActiveStep((prev) => prev + 1);
  };

  const handlePrepareSurvey = async () => {
    setLoading(true);
    setError('');
    try {
      const customerData = {
        nationalId: formData.id || '',
        title: formData.preName || '',
        firstName: formData.name || '',
        lastName: formData.surname || '',
        address: formData.address || '',
        zoneL1: formData.province || '',
        zoneL2: formData.district || '',
        zoneL3: formData.subDistrict || '',
        zoneL4: formData.house || '',
      };

      const response = await prepareCustomerSurvey(customerData);
      setSurveyData(response);
    } catch (err) {
      console.error('Error preparing survey:', err);
      setError('เกิดข้อผิดพลาดในการเตรียมข้อมูลสำรวจ กรุณาลองใหม่');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const hasRequiredData = formData.id && formData.preName && formData.name && formData.surname && formData.address;

    if (hasRequiredData) {
      handlePrepareSurvey();
    }
  }, [JSON.stringify(formData)]);

  return (
    <Box sx={{ width: '100%', maxWidth: '100%', padding: 2 }}>
      <Typography
        variant="h5"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 2 }}
      >
        เลือกสมาชิก
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {loading ? (
          <CircularProgress sx={{ margin: '0 auto' }} />
        ) : error ? (
          <Typography color="error" align="center">
            {error}
          </Typography>
        ) : surveyData ? (
          <Paper elevation={3} sx={{ padding: 2, boxShadow: 3, borderRadius: '20px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 2,
                borderRadius: '10px',
                boxShadow: 2,
                mb: 1,
                backgroundColor: getStatusColor(surveyData?.data?.surveyResult),
              }}
            >
              {/* Display the main user's information */}
              <Typography sx={{ flexGrow: 1, fontWeight: 600 }} color="white">
                {surveyData?.data?.displayName}
              </Typography>
            </Box>
            <Typography variant="body1" fontWeight={700} fontSize={16} sx={{ mt: 4, mb: 1 }}>
              บ้านเดียวกัน/ครอบครัว:
            </Typography>
            {surveyData?.data?.customerGroup?.customers?.length > 0 ? (
              surveyData?.data?.customerGroup?.customers?.map((person, personIndex) => (
                <Box
                  key={personIndex}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 2,
                    borderRadius: '10px',
                    boxShadow: 2,
                    mb: 1,
                    backgroundColor: getStatusColor(person?.surveyResult),
                  }}
                >
                  <Checkbox
                    checked={checkedPeople[`customerGroup-${personIndex}`] || false}
                    onChange={() => handleCheck('customerGroup', personIndex)}
                    color="primary"
                  />
                  <Typography sx={{ flexGrow: 1, fontWeight: 600 }} color="white">
                    {person?.displayName}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography color="text.secondary">ไม่มีสมาชิกในกลุ่มนี้</Typography>
            )}

            <Typography variant="body1" fontWeight={700} fontSize={16} sx={{ mt: 4, mb: 1 }}>
              นามสกุลเดียวกัน:
            </Typography>
            {surveyData?.data?.__lastNameGroup?.length > 0 ? (
              surveyData.data.__lastNameGroup.map((person, personIndex) => (
                <Box
                  key={personIndex}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 2,
                    borderRadius: '10px',
                    boxShadow: 2,
                    mb: 1,
                    backgroundColor: getStatusColor(person?.surveyResult),
                  }}
                >
                  <Checkbox
                    checked={checkedPeople[`__lastNameGroup-${personIndex}`] || false}
                    onChange={() => handleCheck('__lastNameGroup', personIndex)}
                    color="primary"
                  />
                  <Typography sx={{ flexGrow: 1, fontWeight: 600 }} color="white">
                    {person?.displayName}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography color="text.secondary">ไม่มีสมาชิกในกลุ่มนี้</Typography>
            )}

            <Typography variant="body1" fontWeight={700} fontSize={16} sx={{ mt: 4, mb: 1 }}>
              เพิ่มมาเอง:
            </Typography>
            {addManualContact?.length > 0 ? (
              addManualContact.map((person, personIndex) => (
                <Box
                  key={`addManualContact-${personIndex}`}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 2,
                    borderRadius: '10px',
                    boxShadow: 2,
                    mb: 1,
                    backgroundColor: getStatusColor(person?.surveyResult),
                  }}
                >
                  <Checkbox
                    checked={checkedPeople[`addManualContact-${personIndex}`] || false}
                    onChange={() => handleCheck('addManualContact', personIndex)}
                    color="primary"
                  />
                  <Typography sx={{ flexGrow: 1, fontWeight: 600 }} color="white">
                    {person?.displayName}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography color="text.secondary">ไม่มีสมาชิกในกลุ่มนี้</Typography>
            )}

            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', padding: 2, gap: 2 }}>
              <Button onClick={() => setActiveStep(7)} variant="contained" color="primary" sx={{ flexGrow: 1 }}>
                ค้นหาด้วยตัวเอง
              </Button>
              <Button onClick={handleConfirmSelection} variant="contained" color="primary" sx={{ flexGrow: 1 }}>
                ยืนยัน
              </Button>
            </Box>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => navigate(-1)}
              sx={{
                fontWeight: 700,
                paddingY: 1.5,
                my: 1,
              }}
            >
              ย้อนกลับ
            </Button>
          </Paper>
        ) : (
          <Typography color="text.secondary" align="center">
            ไม่มีข้อมูลสมาชิก
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SelectContactPage;
