import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Paper,
  Divider,
  Button,
  Breadcrumbs,
  CircularProgress, // Import loading spinner
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import theme from '../../theme/theme';
import { AddCircleOutline } from '@mui/icons-material';
import { getCustomerById } from '../../service/customer-service';
import { getGifts } from '../../service/contact-service';

const ContactedAddPage = () => {
  const { customerId, selectedId } = useParams();
  const navigate = useNavigate();

  // State for customer data, gifts, loading, error, and pagination
  const [customerData, setCustomerData] = useState(null);
  const [gifts, setGifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const limit = 10; // Default items per page
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const customerResponse = await getCustomerById(selectedId);
        const giftsResponse = await getGifts();
        console.log(customerResponse, 'customerResponse');

        if (customerResponse && customerResponse.data) {
          setCustomerData(customerResponse.data);
        }

        if (giftsResponse && giftsResponse.data) {
          setGifts(giftsResponse.data);
        }

        // Simulate setting the total count (you can adjust this logic)
        setTotalCount(customerResponse.data?.customerGroup?.length || 0);
      } catch (err) {
        setError('Failed to fetch data.');
      }

      setLoading(false);
    };

    fetchData();
  }, [customerId]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'HIGH':
        return theme.palette.component.good;
      case 'LOW':
        return theme.palette.component.improve;
      case 'MEDIUM':
        return theme.palette.component.medium;
      default:
        return theme.palette.component.default;
    }
  };

  const getGiftIcon = (iconName) => {
    switch (iconName) {
      case 'apple':
        return '🍎';
      case 'banana':
        return '🍌';
      case 'orange':
        return '🍊';
      case 'grape':
        return '🍇';
      default:
        return '🎁';
    }
  };

  const findGiftIconById = (giftId) => {
    const gift = gifts.find((g) => g.id === parseInt(giftId)); // Parse giftId to ensure type consistency
    return gift ? getGiftIcon(gift.iconName) : '🎁';
  };

  const totalPages = Math.ceil(totalCount / limit);

  const handlePageChange = (_, value) => {
    setPage(value);
  };

  // Show loading spinner or error message
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: 4 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!customerData) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: 4 }}>
        <Typography variant="h6" color="text.secondary">
          No customer data available.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%', // Full width
        maxWidth: '100%', // Ensuring it occupies full width
        padding: 2,
      }}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/home" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
          หน้าหลัก
        </Link>
        <Link to="/contacted-list" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
          รายชื่อที่คุยแล้ว
        </Link>
        <Link
          to={`/contacted-edit/${customerId}`}
          style={{ textDecoration: 'none', color: theme.palette.primary.main }}
        >
          เลือกสมาชิก
        </Link>
        <Typography color="text.primary">สมาชิก</Typography>
      </Breadcrumbs>
      <Typography
        variant="h5"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 2 }}
      >
        สมาชิก
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2, // Adds spacing between the boxes
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 2,
            boxShadow: 3,
            borderRadius: '20px', // Add border-radius here
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 2,
              borderRadius: '10px',
              boxShadow: 2,
              mb: 1, // Margin between items
              backgroundColor: getStatusColor(customerData?.surveyResult), // Set background color
            }}
          >
            <Typography sx={{ flexGrow: 1, fontWeight: 600 }} color="white">
              {customerData?.displayName}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {customerData?.giftMeta.map((gift, idx) => (
                <Typography key={idx} sx={{ fontWeight: 500 }}>
                  {findGiftIconById(gift.id)} {gift.count}
                </Typography>
              ))}
            </Box>
          </Box>

          <Button
            onClick={() =>
              navigate('/add-contact-main', {
                state: {
                  fromPage: 'addPage',
                  preName: `${customerData?.title}`,
                  name: `${customerData?.firstName}`,
                  surname: `${customerData?.lastName}`,
                  id: `${customerData?.nationalId}`,
                  address: `${customerData?.address}`,
                  zoneL1: `${customerData?.zoneL1}`,
                  zoneL2: `${customerData?.zoneL2}`,
                  zoneL3: `${customerData?.zoneL3}`,
                  zoneL4: `${customerData?.zoneL4}`,
                },
              })
            }
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<AddCircleOutline />}
            sx={{ textTransform: 'none', borderRadius: '8px', mt: 3 }}
          >
            ทำเพิ่ม
          </Button>
        </Paper>
      </Box>
      <Button
        variant="outlined"
        fullWidth
        onClick={() => navigate(-1)}
        sx={{
          mt: 3,
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          fontWeight: 600,
          '&:hover': {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
          },
        }}
      >
        ย้อนกลับ
      </Button>
    </Box>
  );
};

export default ContactedAddPage;
