import axiosInstance from '../util/axios-instance';

// Get staff by working team
export const getStaffByWorkingTeam = async (workingTeamId, offset = 0, max = 10) => {
  try {
    const response = await axiosInstance.post(`/api/staff/where?offset=${offset}&max=${max}`, {
      where: '_.workingTeam = :workingTeam',
      params: {
        workingTeam: workingTeamId,
      },
    });
    return response.data; // contains staff information
  } catch (error) {
    console.error('Error fetching staff:', error);
  }
};

export const getStaffByWorkingTeamSum = async (workingTeamId, offset = 0, max = 10) => {
  try {
    const response = await axiosInstance.post(`/api/staff/where??offset=${offset}&max=${max}&view=__workSummary`, {
      where: '_.workingTeam = :workingTeam',
      params: {
        workingTeam: workingTeamId,
      },
    });
    return response.data; // contains staff information
  } catch (error) {
    console.error('Error fetching staff:', error);
  }
};

export const getStaffById = async (staffId) => {
  try {
    // Replace :staffId with the actual staffId in the URL
    const response = await axiosInstance.get(`/api/staff/${staffId}`);
    return response.data; // contains staff information
  } catch (error) {
    console.error('Error fetching config:', error);
  }
};
