import axiosInstance from '../util/axios-instance';

// Get managed zones with customers based on survey status
export const getZonesBySurveyStatus = async (isSurveyed = false, offset = 0, max = 10) => {
  try {
    const surveyCondition = isSurveyed
      ? 'customers.surveyResult IS NOT NULL' // For customers who have been surveyed
      : 'customers.surveyResult IS NULL'; // For customers who haven't been surveyed

    const response = await axiosInstance.post(
      `/api/managedZone/where?offset=${offset}&max=${max}&view=__surveySummary&additional=unmanagedCustomers`,
      {
        where: surveyCondition,
      }
    );
    return response.data; // contains zones and customers data
  } catch (error) {
    console.error('Error fetching zones by survey status:', error);
  }
};

// Get a managed zone by ID with survey summary
export const getZoneByIdWithSurveySummary = async (managedZoneId) => {
  try {
    const response = await axiosInstance.get(`/api/managedZone/${managedZoneId}?view=__surveySummary`);
    return response.data; // contains the zone details and survey summary
  } catch (error) {
    console.error('Error fetching zone ${managedZoneId} with survey summary:, error');
  }
};

// Get gifts with a filter
export const getGifts = async (where = '', params = {}) => {
  try {
    const response = await axiosInstance.post(
      '/api/gift/where?offset=0&max=10', // Adjust the endpoint if needed
      {
        where, // Pass 'where' as part of the body
        params, // Pass 'params' as part of the body
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching gifts:', error);
  }
};
