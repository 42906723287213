import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, Paper, CircularProgress } from '@mui/material';
import { getFileById } from '../../../service/customer-service';
import { ImageNotSupported } from '@mui/icons-material';

const AddContactingFormPage = ({ setActiveStep, photoData, setFormData, formData }) => {
  const [isLoading, setIsLoading] = useState(false); // For photo loading and form submission
  const [error, setError] = useState(''); // General form error
  const [idError, setIdError] = useState(''); // Specific Thai ID error
  const [fieldErrors, setFieldErrors] = useState({}); // Specific errors for other fields
  const [photoURL, setPhotoURL] = useState(null); // Photo URL
  const [imageError, setImageError] = useState(''); // Error for loading the image
  const formLabel = [
    'คำนำหน้าชื่อ',
    'ชื่อ',
    'สกุล',
    'เลขบัตรประชาชน',
    'ที่อยู่',
    'จังหวัด',
    'อำเภอ',
    'ตำบล',
    'หมู่บ้าน',
  ];

  // Fetch the photo

  const fetchPhoto = async () => {
    if (!photoData) {
      setPhotoURL(null);
      return;
    }

    setIsLoading(true);
    setImageError('');
    try {
      const fileResponse = await getFileById(photoData); // API call
      console.log(fileResponse, 'fileResponse');

      // Check if the response is a blob or URL
      if (fileResponse?.url) {
        // If API provides a URL
        console.log(fileResponse.url, 'fileResponse?.url');
        setPhotoURL(fileResponse.url);
      } else {
        // If API provides binary data (Blob or ArrayBuffer)
        const blob = new Blob([fileResponse], { type: 'image/png' }); // Adjust MIME type as needed
        const tempUrl = URL.createObjectURL(blob);
        setPhotoURL(tempUrl);
      }
    } catch (error) {
      console.error('Error fetching photo:', error);
      setImageError('ไม่สามารถโหลดภาพได้ กรุณาลองใหม่');
      setPhotoURL(null);
    } finally {
      setIsLoading(false);
    }
  };
  // Cleanup temporary object URLs

  useEffect(() => {}, [photoData]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Clear specific field error when the user types
    if (fieldErrors[name]) {
      setFieldErrors((prev) => ({ ...prev, [name]: '' }));
    }

    // Validate Thai ID
    if (name === 'id') {
      validateThaiId(value);
    }
  };

  // Validate Thai ID function
  const validateThaiId = (id) => {
    const cleanedId = id.replace(/\D/g, ''); // Remove non-digit characters
    if (cleanedId.length !== 13) {
      setIdError('เลขบัตรประชาชนต้องมี 13 หลัก');
      return false;
    }

    const digits = cleanedId.split('').map(Number);
    let sum = 0;

    for (let i = 0; i < 12; i++) {
      sum += digits[i] * (13 - i);
    }

    const checkDigit = (11 - (sum % 11)) % 10;
    if (checkDigit !== digits[12]) {
      setIdError('เลขบัตรประชาชนไม่ถูกต้อง');
      return false;
    }

    setIdError('');
    return true;
  };

  // Validate all form fields
  const validateForm = () => {
    const requiredFields = ['preName', 'name', 'surname', 'id', 'address', 'province', 'district', 'subDistrict'];
    const newFieldErrors = {};

    for (let field of requiredFields) {
      if (!formData[field] || formData[field].trim() === '') {
        newFieldErrors[field] = `กรุณากรอก ${field} ให้ครบถ้วน`;
      }
    }

    setFieldErrors(newFieldErrors);

    if (!validateThaiId(formData.id)) {
      setError('กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง');
      return false;
    }

    // Return false if there are any errors
    return Object.keys(newFieldErrors).length === 0;
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm() || idError) {
      setError('กรุณากรอกข้อมูลให้ครบถ้วนและถูกต้อง');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      setActiveStep((prev) => prev + 1);
    } catch (err) {
      setError('เกิดข้อผิดพลาดในการส่งข้อมูล');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'background.default',
        padding: 2,
      }}
    >
      <Typography variant="h5" align="center" gutterBottom sx={{ color: 'primary.main', fontWeight: 700 }}>
        รูปบัตรประชาชนของคุณ
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '200px',
          width: '100%',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : imageError ? (
          <Typography color="error" align="center">
            {imageError}
          </Typography>
        ) : photoURL ? (
          <img src={photoURL} alt="Captured ID" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            {photoData ? (
              <img
                src={photoData}
                alt="Captured"
                style={{
                  width: '100%',
                  maxWidth: '400px',
                  borderRadius: '8px',
                  marginBottom: 16,
                  border: '2px solid #ccc',
                }}
              />
            ) : (
              <Box sx={{ textAlign: 'center' }}>
                <ImageNotSupported sx={{ fontSize: 60, color: 'text.secondary' }} />
                <Typography color="text.secondary">ไม่มีรูปภาพให้แสดง</Typography>
              </Box>
            )}
          </Box>
        )}

        {/* {photoURL ? (
          <img src={photoURL} alt="Captured ID" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <ImageNotSupported sx={{ fontSize: 60, color: 'text.secondary' }} />
            <Typography color="text.secondary">ไม่มีรูปภาพให้แสดง</Typography>
          </Box>
        )} */}
      </Box>

      <Paper elevation={3} sx={{ padding: 3, maxWidth: 500, width: '100%', borderRadius: '12px' }}>
        <form onSubmit={handleSubmit}>
          {['preName', 'name', 'surname', 'id', 'address', 'province', 'district', 'subDistrict', 'house'].map(
            (field, index) => (
              <TextField
                key={index}
                label={`${formLabel[index]}`}
                name={field}
                value={formData[field] || ''}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                sx={{ marginBottom: 2 }}
                error={!!fieldErrors[field] || (field === 'id' && !!idError)}
                helperText={fieldErrors[field] || (field === 'id' && idError) || ''}
              />
            )
          )}

          {error && (
            <Typography variant="body2" color="error" align="center" sx={{ marginBottom: 2 }}>
              {error}
            </Typography>
          )}

          <Box display="flex" justifyContent="space-between" sx={{ marginTop: 2 }}>
            <Button
              variant="outlined"
              type="button"
              fullWidth
              onClick={() => setActiveStep((prev) => prev - 1)}
              sx={{ marginRight: 1 }}
            >
              ย้อนกลับ
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              sx={{ marginLeft: 1 }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : 'ยืนยัน'}
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default AddContactingFormPage;
