import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import theme from '../../../theme/theme';
import { getGifts } from '../../../service/contact-service';

const AddContactingCompletePage = ({ completeData, itemCounts }) => {
  const navigate = useNavigate();

  const [gifts, setGifts] = useState([]);

  console.log(itemCounts, 'itemCountsitemCountsitemCountsitemCounts');

  // Assuming getGifts is defined elsewhere or imported
  useEffect(() => {
    const fetchData = async () => {
      const giftsData = await getGifts();

      if (giftsData && giftsData.data) {
        setGifts(giftsData.data);
      }
    };

    fetchData();
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case 'HIGH':
        return theme.palette.component.good;
      case 'LOW':
        return theme.palette.component.improve;
      case 'MEDIUM':
        return theme.palette.component.medium;
      default:
        return theme.palette.component.default;
    }
  };

  // Function to get the gift icon based on the gift id
  const getGiftIcon = (giftId) => {
    // Find the gift data by its id
    const gift = gifts.find((g) => g.id === giftId);
    if (gift) {
      switch (gift.iconName) {
        case 'apple':
          return '🍎';
        case 'banana':
          return '🍌';
        case 'orange':
          return '🍊';
        case 'grape':
          return '🍇';
        default:
          return '🎁'; // Default if iconName is not recognized
      }
    }
    return '🎁'; // Default if gift is not found
  };
  // const getGiftIcon = (giftId) => {
  //   // Loop over the gifts to find the right one by ID, assuming giftId matches
  //   const gift = gifts.find((g) => g.id === giftId);
  //   return gift ? gift.icon : '🎁';
  // };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        padding: 2,
      }}
    >
      <Typography
        variant="h5"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 2 }}
      >
        บันทึกข้อมูลเรียบร้อย
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {/* Loop through the completeData's array and render each user's information */}

        <Paper
          elevation={3}
          sx={{
            padding: 2,
            boxShadow: 3,
            borderRadius: '20px',
          }}
        >
          {completeData?.data?.map((user, index) => (
            <Link
              to={`/contacted-edit/${user.id}`} // Assuming you want to edit this user's information
              key={index}
              style={{ color: 'black', textDecoration: 'none' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: 2,
                  borderRadius: '10px',
                  boxShadow: 2,
                  mb: 1,
                  backgroundColor: getStatusColor(user?.surveyResult),
                }}
              >
                {/* User's Name */}
                <Typography sx={{ flexGrow: 1, fontWeight: 600 }}>{user?.displayName}</Typography>

                {/* Gifts */}
              </Box>
            </Link>
          ))}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, my: 5 }}>
            <Typography fontWeight={700}>บริจาคต่อคน:</Typography>
            {itemCounts?.map((gift, idx) => (
              <Typography key={idx} sx={{ fontWeight: 500 }}>
                {getGiftIcon(gift?.id)} {gift?.count / completeData?.data?.length} {/* Display the icon for the gift */}
              </Typography>
            ))}
          </Box>
          <Button variant="contained" color="primary" fullWidth onClick={() => navigate('/home')}>
            เสร็จสิ้น
          </Button>
        </Paper>
      </Box>
    </Box>
  );
};

export default AddContactingCompletePage;
