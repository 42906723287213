const typography = {
  fontFamily: '"Noto Sans Thai", "Roboto", "Arial", sans-serif',
  h1: {
    fontSize: '2.5rem',
    fontWeight: 700,
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  body1: {
    fontSize: '1rem',
  },
};

export default typography;
