import { useEffect, useRef } from 'react';
import { useAuth } from './auth-context';

const useInactivityLogout = (timeout = 3 * 60 * 1000) => {
  const { logout, user } = useAuth(); // Include `user` to check if the user is authenticated
  const timerRef = useRef();

  const resetTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);

    // Set the timer only if the user is authenticated
    if (user) {
      timerRef.current = setTimeout(() => {
        if (logout) {
          console.error('pass');
          logout();
        } else {
          console.error('Logout function is not available in AuthContext.');
        }
      }, timeout);
    }
  };

  useEffect(() => {
    if (!user) return; // If user doesn't exist, skip adding event listeners

    const events = ['mousemove', 'keydown', 'click', 'touchstart', 'touchmove'];
    const handleActivity = () => resetTimer();

    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    resetTimer();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [logout, timeout, user]);
};

export default useInactivityLogout;
