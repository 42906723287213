// Layout.js
import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box, Button, ButtonBase, Container, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ArrowBackIos, ArrowLeftOutlined, Logout } from '@mui/icons-material';
import { useAuth } from '../context/auth-context';

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get current location
  const theme = useTheme();
  const auth = useAuth();

  const handleLogout = () => {
    auth.setAccessToken('');
    navigate('/login');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header */}
      <AppBar
        position="sticky"
        sx={{
          background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
          color: 'white',
        }}
      >
        <Toolbar>
          {/* don't show this in /home */}
          <Box sx={{ flexGrow: 1 }}>
            {location.pathname !== '/home' && (
              <IconButton onClick={() => navigate(-1)} sx={{ color: 'white' }}>
                <ArrowBackIos />
              </IconButton>
            )}
          </Box>

          <IconButton
            size="small"
            onClick={() => {
              handleLogout();
            }}
            sx={{ color: 'red', backgroundColor: 'white' }}
          >
            <Logout sx={{ fontSize: 18 }} />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 64px)',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Container
          maxWidth="false"
          disableGutters
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            p: 0,
          }}
        >
          <Outlet /> {/* Renders the matched child route */}
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
