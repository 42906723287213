import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, Divider, ButtonBase, Breadcrumbs } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import theme from '../../theme/theme';
import { getStaffByWorkingTeamSum } from '../../service/team-service';

const TeamWorkingPage = () => {
  const { workingTeamId } = useParams();
  const [staffList, setStaffList] = useState([]);
  const [totalCount, setTotalCount] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStaffData = async () => {
      try {
        const staffData = await getStaffByWorkingTeamSum(workingTeamId);
        setStaffList(staffData?.data || []);
        setTotalCount(`${staffData?.totalCount}` || '-');
      } catch (error) {
        console.error('Error fetching staff:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (workingTeamId) {
      fetchStaffData();
    }
  }, [workingTeamId]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'good':
        return theme.palette.component.good;
      case 'improve':
        return theme.palette.component.improve;
      case 'medium':
        return theme.palette.component.medium;
      default:
        return theme.palette.component.default;
    }
  };

  return (
    <Box
      sx={{
        width: '100%', // Full width
        maxWidth: '100%', // Ensuring it occupies full width
        padding: 2,
        // bgcolor: 'background.paper',
      }}
    >
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/home" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
          หน้าหลัก
        </Link>
        <Typography color="text.primary">ลูกทีมทั้งหมด</Typography>
      </Breadcrumbs>
      <Typography
        variant="h5"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 2 }}
      >
        ลูกทีมทั้งหมด
      </Typography>
      <Box display="flex" justifyContent="space-around" alignItems="center" sx={{ my: 2 }}>
        <Typography align="center" gutterBottom sx={{ color: theme.palette.primary.main }}>
          ทั้งหมด: {totalCount}
        </Typography>
        <Typography align="center" gutterBottom sx={{ color: theme.palette.primary.main }}>
          คุยแล้ว:
        </Typography>
        <Typography align="center" gutterBottom sx={{ color: theme.palette.primary.main }}>
          ยังไม่คุย:
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2, // Adds spacing between the boxes
        }}
      >
        {/* Mapping over sections to create Paper for each */}

        <Paper
          elevation={3}
          sx={{
            padding: 2,
            boxShadow: 3,
            borderRadius: '20px', // Add border-radius here
          }}
        >
          {/* <Typography variant="body1" fontWeight={700} fontSize={16} sx={{ mb: 1 }}>
              พื้นที่: {section?.section}
            </Typography> */}

          {/* List people within the section */}
          {staffList.map((staff, index) => {
            return (
              <Link
                to={`/team-working-detail/${workingTeamId}/${staff?.id}`} // Define the path to navigate to
                key={staff?.id}
                style={{ color: 'black', textDecoration: 'none' }} // Remove default link styling
              >
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 2,
                    borderRadius: '10px',
                    boxShadow: 2,
                    mb: 1, // Margin between items
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  {/* Person's Name */}
                  <Typography sx={{ flexGrow: 1, fontWeight: 600 }} color="white">
                    {staff?.displayName}
                  </Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography sx={{ fontWeight: 500 }} color="white">
                      {staff?.__workSummary.doneCount}/{staff?.__workSummary.totalCount}
                    </Typography>
                  </Box>
                </Box>
              </Link>
            );
          })}
        </Paper>
      </Box>
    </Box>
  );
};

export default TeamWorkingPage;
