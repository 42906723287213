import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, Button } from '@mui/material';
import theme from '../../../theme/theme';
import { getGifts } from '../../../service/contact-service';

const AddListForContactPage = ({ setActiveStep, setSelectedPeople, selectedPeople, itemCounts, setItemCounts }) => {
  const [gifts, setGifts] = useState([]);

  useEffect(() => {
    setItemCounts([]);
    const fetchData = async () => {
      const giftsData = await getGifts();
      if (giftsData && giftsData.data) {
        setGifts(giftsData.data);
      }
    };
    fetchData();
  }, []);

  const handleCountChange = (item, delta) => {
    const totalIncrement = delta * selectedPeople.length; // Total increment for all people

    // Update `itemCounts` for the input UI
    setItemCounts((prevCounts) => {
      const itemIndex = prevCounts.findIndex((entry) => entry.id === item.id);
      if (itemIndex !== -1) {
        // Update existing item count
        const updatedCounts = [...prevCounts];
        updatedCounts[itemIndex].count = Math.max(0, updatedCounts[itemIndex].count + totalIncrement);
        return updatedCounts;
      } else {
        // Add new item entry
        return [...prevCounts, { id: item.id, count: Math.max(0, totalIncrement) }];
      }
    });

    // Recalculate and distribute counts among selected people
    setSelectedPeople((prevPeople) => {
      // Calculate new total count for this item
      const updatedItemCount = itemCounts.find((entry) => entry.id === item.id)?.count || 0;
      const newTotalCount = Math.max(0, updatedItemCount + totalIncrement);

      // Distribute evenly
      const baseCount = Math.floor(newTotalCount / selectedPeople.length);
      let remaining = newTotalCount % selectedPeople.length;

      return prevPeople.map((person, index) => {
        const updatedGiftMeta = person.giftMeta ? [...person.giftMeta] : [];
        const existingGift = updatedGiftMeta.find((gift) => gift.id === item.id);

        console.log(updatedGiftMeta, 'updatedGiftMeta');
        console.log(existingGift, 'updatedGiftMeta');

        if (existingGift) {
          // Assign base count and distribute remaining items
          existingGift.count = baseCount + (remaining > 0 ? 1 : 0);
          remaining = Math.max(0, remaining - 1);
        } else if (newTotalCount > 0) {
          // Add a new gift entry if incrementing
          updatedGiftMeta.push({ id: item.id, count: baseCount + (remaining > 0 ? 1 : 0) });
          remaining = Math.max(0, remaining - 1);
        }

        return {
          ...person,
          giftMeta: updatedGiftMeta,
        };
      });
    });
  };

  const handleNextStep = () => {
    // Distribute the counts from itemCounts among the selected people
    const updatedPeople = selectedPeople.map((person) => {
      const updatedGiftMeta = person.giftMeta ? [...person.giftMeta] : [];

      itemCounts.forEach((item) => {
        const existingGift = updatedGiftMeta.find((gift) => gift.id === item.id);

        // Divide count evenly among all selected people
        const sharedCount = Math.floor(item.count / selectedPeople.length);

        if (existingGift) {
          // Update count if the gift already exists
          existingGift.count += sharedCount;
        } else {
          // Add a new gift entry if it doesn't exist
          updatedGiftMeta.push({
            id: item.id,
            count: sharedCount,
          });
        }
      });

      return {
        ...person,
        giftMeta: updatedGiftMeta,
      };
    });

    console.log('Updated People with Gift Counts:', updatedPeople);

    setActiveStep((prev) => prev + 1);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'HIGH':
        return theme.palette.component.good;
      case 'LOW':
        return theme.palette.component.improve;
      case 'MEDIUM':
        return theme.palette.component.medium;
      default:
        return theme.palette.component.default;
    }
  };

  const getGiftIcon = (iconName) => {
    switch (iconName) {
      case 'apple':
        return '🍎';
      case 'banana':
        return '🍌';
      case 'orange':
        return '🍊';
      case 'grape':
        return '🍇';
      default:
        return '🎁';
    }
  };
  const findGiftIconById = (giftId) => {
    const gift = gifts.find((g) => g.id === parseInt(giftId)); // Parse giftId to ensure type consistency
    return gift ? getGiftIcon(gift.iconName) : '🎁';
  };

  return (
    <Box sx={{ width: '100%', padding: 2, backgroundColor: '#f0f2f5' }}>
      <Typography
        variant="h4"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 3 }}
      >
        เลือกสมาชิก
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Paper elevation={3} sx={{ padding: 3, boxShadow: 4, borderRadius: '20px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {selectedPeople?.length > 0 ? (
              selectedPeople?.map((person, idx) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 2,
                    borderRadius: '10px',
                    boxShadow: 2,
                    mb: 1,
                    backgroundColor: getStatusColor(person.surveyResult),
                  }}
                >
                  <Typography sx={{ flexGrow: 1, fontWeight: 600 }} color="white">
                    {person.displayName}
                  </Typography>

                  {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {person.giftMeta?.map((gift, idx) => (
                      <Typography key={idx} sx={{ fontWeight: 500 }}>
                        {findGiftIconById(gift.id)} {gift.count}
                      </Typography>
                    ))}
                  </Box> */}
                </Box>
              ))
            ) : (
              <Typography>No selected people to display.</Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, my: 5 }}>
            <Typography fontWeight={700}>บริจาคต่อคน:</Typography>
            {itemCounts?.map((gift, idx) => (
              <Typography key={idx} sx={{ fontWeight: 500 }}>
                {findGiftIconById(gift?.id)} {gift?.count / selectedPeople?.length}{' '}
                {/* Display the icon for the gift */}
              </Typography>
            ))}
          </Box>

          <Typography
            variant="h5"
            fontWeight={700}
            align="center"
            gutterBottom
            sx={{ color: theme.palette.primary.main, mt: 3 }}
          >
            เลือกรายการ
          </Typography>

          {gifts?.map((item) => {
            const itemCount = itemCounts.find((entry) => entry.id === item.id)?.count || 0;
            return (
              <Box
                key={item.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: '#e0f7fa',
                  padding: 1,
                  borderRadius: 2,
                  mb: 2,
                }}
              >
                <Typography sx={{ fontSize: '18px', display: 'flex', alignItems: 'center' }}>
                  {getGiftIcon(item?.iconName)} {item?.name}
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{
                      minWidth: '30px',
                      padding: 0,
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    }}
                    onClick={() => handleCountChange(item, -1)}
                  >
                    -
                  </Button>
                  <Typography
                    sx={{
                      minWidth: '40px',
                      textAlign: 'center',
                      border: '1px solid black',
                      borderRadius: '5px',
                      backgroundColor: '#ffffff',
                    }}
                  >
                    {itemCount}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      minWidth: '30px',
                      padding: 0,
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    }}
                    onClick={() => handleCountChange(item, 1)}
                  >
                    +
                  </Button>
                </Box>
              </Box>
            );
          })}

          <Box display="flex" justifyContent="space-between" sx={{ marginTop: 2 }}>
            <Button
              variant="outlined"
              type="button"
              fullWidth
              onClick={() => {
                setActiveStep((prev) => prev - 1);
              }}
              sx={{
                fontWeight: 700,
                paddingY: 1.5,
                marginRight: 1,
              }}
            >
              ย้อนกลับ
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              onClick={handleNextStep}
              sx={{
                fontWeight: 700,
                paddingY: 1.5,
                marginLeft: 1,
              }}
            >
              เพิ่ม
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default AddListForContactPage;
