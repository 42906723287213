import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, Button, Select, MenuItem, CircularProgress, Alert } from '@mui/material';

import theme from '../../../theme/theme';
import { useNavigate } from 'react-router-dom';
import { customerSubmitSurvey } from '../../../service/customer-service';
import { getGifts } from '../../../service/contact-service';

const ReviewAddContactPage = ({
  setActiveStep,
  location,
  setLocation,
  loading,
  setLoading,
  selectedPeople,
  itemCounts,
  setCompleteData,
}) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [gifts, setGifts] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const giftsData = await getGifts();
        if (giftsData?.data) {
          setGifts(giftsData.data);
        }
      } catch (error) {
        console.error('Error fetching gifts:', error);
        setError('เกิดข้อผิดพลาดในการดึงข้อมูลของขวัญ');
      }
    };
    fetchData();
  }, []);

  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        (error) => {
          console.error('Error fetching location:', error);
          setError('ไม่สามารถดึงตำแหน่งได้');
        }
      );
    } else {
      setError('เบราว์เซอร์นี้ไม่รองรับ Geolocation');
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setError(''); // Clear error when a selection is made
  };

  const handleSubmit = async () => {
    setError('');
    setLoading(true);

    // Validate selected people
    if (!selectedPeople || selectedPeople.length === 0) {
      setError('กรุณาเลือกสมาชิก');
      setLoading(false);
      return;
    }

    // Validate survey result
    if (!selectedValue) {
      setError('กรุณาเลือกความพึงพอใจ');
      setLoading(false);
      return;
    }

    // Validate and adjust gift quantities
    const totalPeople = selectedPeople.length;
    const adjustedGifts = itemCounts
      .filter(({ count }) => count > 0)
      .map(({ id, count }) => ({
        id,
        count: Math.floor(count / totalPeople),
      }))
      .filter(({ count }) => count > 0);

    if (adjustedGifts.length === 0) {
      setError('จำนวนของขวัญไม่เพียงพอสำหรับสมาชิก');
      setLoading(false);
      return;
    }

    const customers = selectedPeople.map((person) => ({ id: person.id }));

    try {
      fetchLocation();

      if (!location?.latitude || !location?.longitude) {
        setError('ตำแหน่งไม่พร้อมใช้งาน');
        setLoading(false);
        return;
      }

      const data = await customerSubmitSurvey(
        customers,
        selectedValue,
        adjustedGifts,
        location.latitude,
        location.longitude
      );

      if (data) {
        setCompleteData(data);
        setActiveStep(8);
      } else {
        setError('ไม่สามารถส่งข้อมูลได้');
      }
    } catch (error) {
      console.error('Error submitting survey data:', error);
      setError('เกิดข้อผิดพลาดระหว่างการส่งข้อมูล');
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'HIGH':
        return theme.palette.component.good;
      case 'LOW':
        return theme.palette.component.improve;
      case 'MEDIUM':
        return theme.palette.component.medium;
      default:
        return theme.palette.component.default;
    }
  };

  const getGiftIcon = (iconName) => {
    switch (iconName) {
      case 'apple':
        return '🍎';
      case 'banana':
        return '🍌';
      case 'orange':
        return '🍊';
      case 'grape':
        return '🍇';
      default:
        return '🎁';
    }
  };

  const findGiftIconById = (giftId) => {
    const gift = gifts.find((g) => g.id === parseInt(giftId, 10));
    return gift ? getGiftIcon(gift.iconName) : '🎁';
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '100%', padding: 2 }}>
      <Typography
        variant="h5"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 2 }}
      >
        เลือกสมาชิก
      </Typography>

      {error && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Paper elevation={3} sx={{ padding: 2, boxShadow: 3, borderRadius: '20px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {selectedPeople?.length > 0 ? (
              selectedPeople.map((person, idx) => (
                <Box
                  key={idx}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 2,
                    borderRadius: '10px',
                    boxShadow: 2,
                    mb: 1,
                    backgroundColor: getStatusColor(person.surveyResult),
                  }}
                >
                  <Typography sx={{ flexGrow: 1, fontWeight: 600 }} color="white">
                    {person.displayName}
                  </Typography>

                  {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {person.giftMeta?.map((gift, idx) => (
                      <Typography key={idx} sx={{ fontWeight: 500 }}>
                        {findGiftIconById(gift.id)} {gift.count}
                      </Typography>
                    ))}
                  </Box> */}
                </Box>
              ))
            ) : (
              <Typography>No selected people to display.</Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, my: 5 }}>
            <Typography fontWeight={700}>บริจาคต่อคน:</Typography>
            {itemCounts?.map((gift, idx) => (
              <Typography key={idx} sx={{ fontWeight: 500 }}>
                {findGiftIconById(gift?.id)} {gift?.count / selectedPeople?.length}{' '}
                {/* Display the icon for the gift */}
              </Typography>
            ))}
          </Box>
          <Typography
            variant="h5"
            fontWeight={700}
            align="center"
            gutterBottom
            sx={{ color: theme.palette.primary.main, my: 2 }}
          >
            ความพึงพอใจ
          </Typography>

          <Select
            value={selectedValue}
            onChange={handleChange}
            displayEmpty
            fullWidth
            sx={{
              mb: 2,
              '& .MuiSelect-select': {
                backgroundColor:
                  selectedValue === 'HIGH'
                    ? theme.palette.component.good
                    : selectedValue === 'MEDIUM'
                    ? theme.palette.component.medium
                    : selectedValue === 'LOW'
                    ? theme.palette.component.improve
                    : 'inherit',
                color: theme.palette.text.primary,
                fontWeight: 'bold',
                borderRadius: 1,
              },
            }}
          >
            <MenuItem value="" sx={{ backgroundColor: theme.palette.background.paper }}>
              <em>เลือกความพึงพอใจ</em>
            </MenuItem>
            <MenuItem
              value="HIGH"
              sx={{ backgroundColor: theme.palette.component.good, color: theme.palette.text.primary }}
            >
              ดี
            </MenuItem>
            <MenuItem
              value="MEDIUM"
              sx={{ backgroundColor: theme.palette.component.medium, color: theme.palette.text.primary }}
            >
              ปานกลาง
            </MenuItem>
            <MenuItem
              value="LOW"
              sx={{ backgroundColor: theme.palette.component.improve, color: theme.palette.text.primary }}
            >
              ปรับปรุง
            </MenuItem>
          </Select>

          <Box display="flex" justifyContent="space-between" sx={{ marginTop: 2 }}>
            <Button
              variant="outlined"
              type="button"
              fullWidth
              onClick={() => {
                setActiveStep((prev) => prev - 1);
              }}
              sx={{
                fontWeight: 700,
                paddingY: 1.5,
                marginRight: 1,
              }}
            >
              ย้อนกลับ
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              onClick={handleSubmit}
              disabled={loading} // Disable button when loading
              sx={{
                fontWeight: 700,
                paddingY: 1.5,
                marginLeft: 1,
              }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'ยืนยัน'}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default ReviewAddContactPage;
