import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Paper,
  Divider,
  ButtonBase,
  Breadcrumbs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import theme from '../../theme/theme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getStaffByWorkingTeam } from '../../service/team-service';

const MyTeamPage = () => {
  const { workingTeamId } = useParams();
  const [staffList, setStaffList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStaffData = async () => {
      try {
        const staffData = await getStaffByWorkingTeam(workingTeamId);
        setStaffList(staffData?.data || []);
      } catch (error) {
        console.error('Error fetching staff:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (workingTeamId) {
      fetchStaffData();
    }
  }, [workingTeamId]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'good':
        return theme.palette.component.good;
      case 'improve':
        return theme.palette.component.improve;
      case 'medium':
        return theme.palette.component.medium;
      default:
        return theme.palette.component.default;
    }
  };
  return (
    <Box
      sx={{
        width: '100%', // Full width
        maxWidth: '100%', // Ensuring it occupies full width
        padding: 2,
        // bgcolor: 'background.paper',
      }}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/home" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
          หน้าหลัก
        </Link>

        <Typography color="text.primary">ลูกทีมทั้งหมด</Typography>
      </Breadcrumbs>
      <Typography
        variant="h5"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 2 }}
      >
        ลูกทีมทั้งหมด
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2, // Adds spacing between the boxes
        }}
      >
        {/* Mapping over sections to create Paper for each */}

        <Paper
          elevation={3}
          sx={{
            padding: 2,
            boxShadow: 3,
            borderRadius: '20px', // Add border-radius here
          }}
        >
          {/* <Typography variant="body1" fontWeight={700} fontSize={16} sx={{ mb: 1 }}>
              พื้นที่: {section?.section}
            </Typography> */}

          {/* List people within the section */}
          {staffList.map((staff, index) => {
            return (
              <Accordion
                key={index}
                sx={{
                  borderRadius: '10px !important',
                  mb: 1,
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.text.primary,
                  '&:before': {
                    display: 'none',
                  },
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                  <Typography sx={{ fontWeight: 600 }} color="white">
                    {staff?.displayName}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: 'white',
                    color: theme.palette.text.secondary,
                    borderRadius: '0 0 10px 10px',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      {/* <Typography fontWeight={600}>เบอร์โทร:</Typography> */}
                      <Typography fontWeight={600}>เบอร์โทร:</Typography>
                      <Typography>{staff?.username ? staff?.username : '-'}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Typography fontWeight={600}>Pin:</Typography>
                      <Typography>{staff?.__password ? staff?.__password : '-'}</Typography>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Paper>
      </Box>
    </Box>
  );
};

export default MyTeamPage;
