import React, { useState } from 'react';
import { Typography, Box, Paper, Button, TextField, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search'; // Importing the Search icon
import { searchCustomer } from '../../../service/customer-service';

const SearchSelectPage = ({ setActiveStep, setAddManualContact, addManualContact }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPeople, setFilteredPeople] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedPeople, setCheckedPeople] = useState({});

  // Function to handle search button click
  const handleSearch = async () => {
    setLoading(true); // Start loading
    try {
      // Fetch data from the API with search term, offset, and max results
      const results = await searchCustomer(searchTerm, 0, 10);
      console.log(results, 'testtttttttttttttttttttt');

      setFilteredPeople(results.data); // Set the data received from the API
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'HIGH':
        return theme.palette.component.good;
      case 'LOW':
        return theme.palette.component.improve;
      case 'MEDIUM':
        return theme.palette.component.medium;
      default:
        return theme.palette.component.default;
    }
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (personId, personData) => {
    setCheckedPeople((prevState) => {
      const updatedState = { ...prevState, [personId]: !prevState[personId] };

      // Update the selected people in setAddManualContact
      const selectedPeople = Object.keys(updatedState)
        .filter((key) => updatedState[key])
        .map((key) => filteredPeople.find((person) => person.id === parseInt(key, 10)));

      setAddManualContact(selectedPeople);

      return updatedState;
    });
  };

  const handleSubmit = () => {
    setActiveStep(2); // Move to the form step
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        padding: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Typography
        variant="h5"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 2 }}
      >
        ค้นหา
      </Typography>

      {/* Search Field and Button in the Same Row */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <TextField
          variant="outlined"
          placeholder="ค้นหาชื่อ..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            backgroundColor: '#fff',
            borderRadius: '8px',
            flexGrow: 1, // Allow TextField to grow
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.grey[400],
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
              },
            },
          }}
        />

        <IconButton
          variant="contained"
          onClick={handleSearch}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              boxShadow: '0 4px 20px rgba(0,0,0,0.2)', // Shadow on hover
            },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SearchIcon /> {/* Add Search Icon */}
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        {/* Display filtered results */}
        {filteredPeople.length > 0 ? (
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              boxShadow: 3,
              borderRadius: '20px',
            }}
          >
            {filteredPeople.map((person, personIndex) => (
              <Box
                key={personIndex}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: 2,
                  borderRadius: '10px',
                  boxShadow: 2,
                  mb: 1,
                  backgroundColor: getStatusColor(person?.surveyResult),
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={!!checkedPeople[person.id]}
                      onChange={() => handleCheckboxChange(person.id, person)}
                    />
                  }
                  label={
                    <Typography sx={{ fontWeight: 600 }} color={'white'}>
                      {person.displayName}
                    </Typography>
                  }
                />
              </Box>
            ))}
          </Paper>
        ) : (
          <Typography variant="body1" align="center">
            ไม่มีผลลัพธ์ที่ตรงกัน
          </Typography>
        )}
      </Box>

      {/* Full-width Back Button */}
      <Button
        onClick={() => {
          handleSubmit();
        }}
        variant="contained"
        color="primary"
        fullWidth
        disabled={addManualContact == 0}
        sx={{ textTransform: 'none', borderRadius: '8px', mt: 3 }} // Optional styling
      >
        ยืนยัน
      </Button>
      <Button
        variant="outlined"
        fullWidth
        onClick={() => setActiveStep(2)}
        sx={{
          mt: 3,
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          fontWeight: 600,
          '&:hover': {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
          },
        }}
      >
        ย้อนกลับ
      </Button>
    </Box>
  );
};

export default SearchSelectPage;
