import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, Avatar, Divider, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../theme/theme';
import { Person } from '@mui/icons-material';
import { getMe } from '../service/auth-service';
import { useAuth } from '../context/auth-context';

const HomePage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const auth = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getMe(); // Fetch user info including roles
        setUser(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const categories = [
    { label: 'รายชื่อที่ยังไม่ได้คุย', icon: '📋', color: theme.palette.primary.main, path: '/uncontacted-list' },
    { label: 'รายชื่อที่คุยแล้ว', icon: '🗨️', color: theme.palette.secondary.main, path: '/contacted-list' },
    {
      label: 'เพิ่มการคุย',
      icon: '➕',
      color: theme.palette.primary.dark,
      path: '/add-contact-main',
    },
    { label: 'สรุปงาน', icon: '📊', color: theme.palette.secondary.dark, path: `/summary/${user?.data?.id}` },
    ...(user?.data?.additionalInfo?.leadingTeams?.length > 0
      ? [
          {
            label: 'ลูกทีมของฉัน',
            icon: '👥',
            color: theme.palette.primary.light,
            path: `/my-team/${user?.data?.additionalInfo?.leadingTeams[0]?.id}`,
          },
        ]
      : []),
    ...(user?.data?.additionalInfo?.leadingTeams?.length > 0
      ? [
          {
            label: 'การทำงานของลูกทีม',
            icon: '🛠️',
            color: theme.palette.secondary.light,
            path: `/team-working/${user?.data?.additionalInfo?.leadingTeams[0]?.id}`,
          },
        ]
      : []),

    // {
    //   label: 'ลูกทีมของฉัน',
    //   icon: '👥',
    //   color: theme.palette.primary.light,
    //   path: `/my-team/${1}`,
    // },
    // {
    //   label: 'การทำงานของลูกทีม',
    //   icon: '🛠️',
    //   color: theme.palette.secondary.light,
    //   path: `/team-working/${1}`,
    // },
  ];

  useEffect(() => {
    if (!auth?.user) {
      auth.setAccessToken('');
      navigate('/login');
    }
  }, [auth?.user]);
  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      {/* Loading State */}
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          {/* User Info */}
          <Box
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              width: '100%',
              maxWidth: '600px',
              padding: '16px',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '16px',
              color: 'white',
              backdropFilter: 'blur(6px)',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
              my: 2,
            }}
          >
            <Avatar sx={{ bgcolor: theme.palette.primary.main, width: 56, height: 56, marginRight: 2 }}>
              <Person />
            </Avatar>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.primary.light }}>
                ยินดีต้อนรับ
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: 'bold', color: 'white', textShadow: '1px 1px 3px rgba(0,0,0,0.4)' }}
              >
                {user?.data?.displayName}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ bgcolor: 'white', height: 2, margin: '16px 0' }} />

          {/* Categories Menu */}
          <Box sx={{ width: '100%', maxWidth: '600px' }}>
            <Typography
              variant="h5"
              sx={{ mb: 2, color: theme.palette.primary.light, fontWeight: 'bold', textAlign: 'center' }}
            >
              เมนูทั้งหมด
            </Typography>

            {categories.length > 0 ? (
              <Grid container spacing={2} justifyContent="center">
                {categories.map((category, index) => (
                  <Grid item xs={6} sm={4} key={index}>
                    <Card
                      variant="outlined"
                      sx={{
                        padding: '12px',
                        backgroundColor: category.color,
                        color: 'white',
                        borderRadius: '12px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.3)',
                        transition: 'transform 0.1s ease, box-shadow 0.1s ease',
                        '&:hover': {
                          transform: 'scale(1.02)',
                          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)',
                        },
                      }}
                      onClick={() => navigate(category.path)}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: '36px',
                          color: 'white',
                          textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                        }}
                      >
                        {category.icon}
                      </Typography>
                      <Typography variant="subtitle2" sx={{ mt: 1, fontWeight: 'bold', fontSize: '0.875rem' }}>
                        {category.label}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  textAlign: 'center',
                  mt: 4,
                  fontStyle: 'italic',
                }}
              >
                ไม่มีเมนูให้แสดง
              </Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default HomePage;
