import axiosInstance from '../util/axios-instance';

// Function to fetch customers with search capabilities
export const searchCustomer = async (searchTerm, offset = 0, max = 10) => {
  try {
    // Format the search key with '%' wildcards around it
    const formattedSearchKey = `%${searchTerm}%`;

    // Set up the request payload
    const requestBody = {
      where: '(_.displayName ilike :searchKey or _.nationalId ilike :searchKey or _.address ilike :searchKey)',
      params: {
        searchKey: formattedSearchKey,
      },
    };

    // Send POST request to the endpoint with the specified offset and max
    const response = await axiosInstance.post(`/api/customer/where?offset=${offset}&max=${max}`, requestBody);

    // Return the response data
    return response.data;
  } catch (error) {
    console.error('Error fetching customers:', error);
  }
};

// Function to fetch a specific customer by their ID
export const getCustomerById = async (customerId) => {
  try {
    // Send GET request to the endpoint with the specified customerId
    const response = await axiosInstance.get(`/api/customer/${customerId}`);

    // Return the response data
    return response.data;
  } catch (error) {
    console.error('Error fetching customer by ID:', error);
  }
};

export const prepareCustomerSurvey = async (customerData = {}) => {
  try {
    // Define the default values
    const defaultData = {
      nationalId: '',
      title: '',
      firstName: '',
      lastName: '',
      address: '',
    };

    // Merge default values with customerData
    const requestData = { ...defaultData, ...customerData };

    // Send POST request to the survey preparation endpoint with the merged data
    const response = await axiosInstance.post(`/api/customer/surveyPrepare`, requestData);

    // Return the response data
    return response.data;
  } catch (error) {
    console.error('Error preparing customer survey:', error);
  }
};

export const customerSubmitSurvey = async (customers, surveyResult, gifts, latitude, longitude) => {
  try {
    // Ensure latitude and longitude are provided
    if (!latitude || !longitude) {
      console.error('Latitude and Longitude are required.');
    }

    // Set up the request payload based on the API requirements
    const requestBody = {
      customers: customers.map((customer) => ({ id: customer.id })), // Extract customer IDs
      surveyResult: surveyResult, // e.g., "MEDIUM"
      gifts: gifts.map((gift) => ({
        id: gift.id, // ID of the gift
        count: gift.count, // Quantity of the gift
      })),
      latitude: latitude, // Include latitude
      longitude: longitude, // Include longitude
    };

    // Send POST request to the submit survey endpoint with the payload
    const response = await axiosInstance.post(`/api/customer/submitSurvey`, requestBody);

    // Return the response data
    return response.data;
  } catch (error) {
    console.error('Error submitting customer survey:', error);
  }
};

// export const uploadFile = async (file) => {
//   try {
//     // Prepare the form data
//     const formData = new FormData();
//     formData.append('file', file);

//     // Send POST request to the file upload endpoint
//     const response = await axiosInstance.post(`/api/file/upload`, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     });

//     // Return the response data
//     return response.data;
//   } catch (error) {
//     console.error('Error uploading file:', error);
//     throw error; // Rethrow for external handling
//   }
// };

export const extractCustomerFromImage = async (file) => {
  try {
    // Prepare the form data
    const formData = new FormData();
    formData.append('file', file);

    // Send POST request to the file upload endpoint
    const response = await axiosInstance.post(`/api/customer/extractFromImage`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Return the response data
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};

// export const extractCustomerFromImage = async (fileId) => {
//   try {
//     // Send GET request to the extract from image endpoint
//     const response = await axiosInstance.get(`/api/customer/extractFromImage/${fileId}`);

//     // Return the response data
//     return response.data;
//   } catch (error) {
//     console.error('Error extracting customer info from image:', error);
//     throw error; // Rethrow for external handling
//   }
// };

export const getFileById = async (fileId) => {
  try {
    const response = await axiosInstance.get(`/api/file/${fileId}`, {
      responseType: 'blob', // Ensures binary data is handled correctly
    });

    // If the API directly returns a URL, you can check for it here
    if (response.data instanceof Blob) {
      // Create a temporary URL from the Blob if it's binary data
      return {
        url: URL.createObjectURL(response.data),
        blob: response.data, // Optional: Include the Blob if needed elsewhere
      };
    } else {
      console.error('Unexpected response type: ', response.data);
    }
  } catch (error) {
    console.error('Error fetching file by ID:', error);
  }
};
