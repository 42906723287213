import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Paper,
  Divider,
  Breadcrumbs,
  Button,
  CircularProgress,
  Pagination,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import theme from '../../theme/theme';
import { getCustomerById } from '../../service/customer-service';
import { getGifts } from '../../service/contact-service';

const ContactedEditPage = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();

  // State for customer data, gifts, loading, error, and pagination
  const [customerData, setCustomerData] = useState(null);
  const [gifts, setGifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const limit = 10; // Default items per page
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const customerResponse = await getCustomerById(customerId);
        const giftsResponse = await getGifts();

        if (customerResponse && customerResponse.data) {
          setCustomerData(customerResponse.data);
        }

        if (giftsResponse && giftsResponse.data) {
          setGifts(giftsResponse.data);
        }

        // Simulate setting the total count (you can adjust this logic)
        setTotalCount(customerResponse.data?.customerGroup?.length || 0);
      } catch (err) {
        setError('Failed to fetch data.');
      }

      setLoading(false);
    };

    fetchData();
  }, [customerId]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'HIGH':
        return theme.palette.component.good;
      case 'LOW':
        return theme.palette.component.improve;
      case 'MEDIUM':
        return theme.palette.component.medium;
      default:
        return theme.palette.component.default;
    }
  };

  const getGiftIcon = (iconName) => {
    switch (iconName) {
      case 'apple':
        return '🍎';
      case 'banana':
        return '🍌';
      case 'orange':
        return '🍊';
      case 'grape':
        return '🍇';
      default:
        return '🎁';
    }
  };

  const findGiftIconById = (giftId) => {
    const gift = gifts.find((g) => g.id === parseInt(giftId)); // Parse giftId to ensure type consistency
    return gift ? getGiftIcon(gift.iconName) : '🎁';
  };

  const totalPages = Math.ceil(totalCount / limit);

  const handlePageChange = (_, value) => {
    setPage(value);
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        padding: 2,
      }}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/home" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
          หน้าหลัก
        </Link>
        <Link to="/contacted-list" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
          รายชื่อที่คุยแล้ว
        </Link>
        <Typography color="text.primary">เลือกสมาชิก</Typography>
      </Breadcrumbs>

      <Typography
        variant="h5"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 2 }}
      >
        เลือกสมาชิก
      </Typography>

      {/* Loading, Error, or Empty State */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress color="primary" />
        </Box>
      ) : error ? (
        <Typography color="error" align="center">
          {error}
        </Typography>
      ) : !customerData ? (
        <Typography align="center" sx={{ color: 'gray' }}>
          ไม่มีข้อมูล
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              boxShadow: 3,
              borderRadius: '20px',
            }}
          >
            <Link to={`/contacted-add/${customerId}/${customerId}`} style={{ color: 'black', textDecoration: 'none' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: 2,
                  borderRadius: '10px',
                  boxShadow: 2,
                  mb: 1,
                  backgroundColor: getStatusColor(customerData?.surveyResult),
                }}
              >
                <Typography sx={{ flexGrow: 1, fontWeight: 600 }} color="white">
                  {customerData?.displayName}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  {customerData?.giftMeta?.map((gift, idx) => (
                    <Typography key={idx} sx={{ fontWeight: 500 }}>
                      {findGiftIconById(gift.id)} {gift.count}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Link>

            <Typography variant="body1" fontWeight={700} fontSize={16} sx={{ mt: 4, mb: 1 }}>
              ครอบครัว:
            </Typography>

            {customerData?.customerGroup?.length > 0 ? (
              customerData.customerGroup.map((person, personIndex) => (
                <Link
                  to={`/contacted-add/${customerId}/${person?.id}`}
                  key={personIndex}
                  style={{ color: 'black', textDecoration: 'none' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: 2,
                      borderRadius: '10px',
                      boxShadow: 2,
                      mb: 1,
                      backgroundColor: getStatusColor(person.status),
                    }}
                  >
                    <Typography sx={{ flexGrow: 1, fontWeight: 600 }} color="white">
                      {person?.displayName}
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      {customerData.giftMeta.map((gift, idx) => (
                        <Typography key={idx} sx={{ fontWeight: 500 }}>
                          {findGiftIconById(gift.id)} {gift.count}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Link>
              ))
            ) : (
              <Typography variant="body1" sx={{ textAlign: 'center', color: 'gray' }}>
                ไม่มีข้อมูลสมาชิก
              </Typography>
            )}
          </Paper>

          {/* Pagination */}
          {totalCount > limit && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                showFirstButton
                showLastButton
              />
            </Box>
          )}
        </Box>
      )}

      <Button
        variant="outlined"
        fullWidth
        onClick={() => navigate(-1)}
        sx={{
          mt: 3,
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          fontWeight: 600,
          '&:hover': {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
          },
        }}
      >
        ย้อนกลับ
      </Button>
    </Box>
  );
};

export default ContactedEditPage;
