import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useAuth } from '../context/auth-context';
import HomePage from '../pages/home-page';
import ContactedListPage from '../pages/contacted-list/contacted-list-page';
import ContactedEditPage from '../pages/contacted-list/contacted-edit-page';
import ContactedAddPage from '../pages/contacted-list/contacted-add-page';
import MyTeamPage from '../pages/team/my-team-page';
import SummaryPage from '../pages/summary/sammary-page';
import TeamWorkingPage from '../pages/team-working/team-working-page';
import TeamWorkingDetailPage from '../pages/team-working/team-working-detail-page';
import AddContactingMainPage from '../pages/add-contacting/add-contacting-main-page';
import AllTeamWorkingPage from '../pages/team-working/all-team-working-page';
import UncontactedListPage from '../pages/uncontacted-list/uncontacted-list-page';
import AllContactSummaryPage from '../pages/summary/all-contact-summary-page';
import LoginPage from '../pages/login-page';
import Layout from '../layout/layout';
import PrivateRoute from './private-route';
import useInactivityLogout from '../context/use-inactivity-logout.js';
import NotFoundPage from '../pages/not-found-page.js';

const AppRoutes = () => {
  useInactivityLogout(3 * 60 * 1000); // Call unconditionally

  const user = useAuth();

  const element = useRoutes([
    { path: '/', element: user ? <Navigate to="/home" replace /> : <Navigate to="/login" replace /> },
    { path: 'login', element: <LoginPage /> },
    {
      path: '/', // Parent path
      element: (
        <PrivateRoute>
          <Layout /> {/* Ensure Layout wraps Outlet */}
        </PrivateRoute>
      ),
      children: [
        { path: 'home', element: <HomePage /> },
        { path: 'uncontacted-list', element: <UncontactedListPage /> },
        { path: 'contacted-list', element: <ContactedListPage /> },
        { path: 'contacted-edit/:customerId', element: <ContactedEditPage /> },
        { path: 'contacted-add/:customerId/:selectedId', element: <ContactedAddPage /> },
        { path: 'my-team/:workingTeamId', element: <MyTeamPage /> },
        { path: 'summary/:staffId', element: <SummaryPage /> },
        { path: 'all-contact-summary-page/:staffId/:zoneId', element: <AllContactSummaryPage /> },
        { path: 'team-working/:workingTeamId', element: <TeamWorkingPage /> },
        { path: 'team-working-detail/:workingTeamId/:staffId', element: <TeamWorkingDetailPage /> },
        { path: 'all-team-contact-page/:workingTeamId/:staffId', element: <AllTeamWorkingPage /> },
        { path: 'add-contact-main', element: <AddContactingMainPage /> },
      ],
    },
    { path: '*', element: <NotFoundPage /> }, // Catch-all for unknown routes
  ]);

  return element;
};

export default AppRoutes;
