import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Paper,
  Divider,
  ButtonBase,
  Breadcrumbs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import theme from '../../theme/theme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getStaffById } from '../../service/team-service';
import { getGifts, getZoneByIdWithSurveySummary } from '../../service/contact-service';

const AllContactSummaryPage = () => {
  const navigate = useNavigate();
  const { zoneId, staffId } = useParams();
  const [staffData, setStaffData] = useState(null);
  const [zoneData, setZoneData] = useState(null);
  const [giftData, setGiftData] = useState([]); // Store gift data
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (zoneId) {
          fetchZoneData(zoneId);
        }

        // Fetch gift data
        const giftResponse = await getGifts();
        setGiftData(giftResponse?.data || []); // Store gift data

        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch initial data:', error);
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [zoneId]);

  const fetchZoneData = async (zoneId) => {
    try {
      const data = await getZoneByIdWithSurveySummary(zoneId);
      console.log(data, 'dddddddddddddddddd');
      setZoneData(data);
    } catch (error) {
      console.error('Failed to fetch zone data:', error);
    }
  };

  // Function to get icon based on gift name
  const getGiftIcon = (iconName) => {
    switch (iconName) {
      case 'apple':
        return '🍎';
      case 'banana':
        return '🍌';
      case 'orange':
        return '🍊';
      case 'grape':
        return '🍇';
      default:
        return '🎁';
    }
  };

  const getSurveyStatus = (result) => {
    const { lowCount, mediumCount, highCount } = result || {};

    if (highCount >= mediumCount && highCount >= lowCount) {
      return 'HIGH';
    } else if (mediumCount >= lowCount) {
      return 'medium';
    } else {
      return 'improve'; // Default to improve if lowCount is highest
    }
  };

  // Define the color based on the status
  const getStatusColor = (status) => {
    switch (status) {
      case 'HIGH':
        return theme.palette.component.good;
      case 'improve':
        return theme.palette.component.improve;
      case 'medium':
        return theme.palette.component.medium;
      default:
        return theme.palette.component.default;
    }
  };

  return (
    <Box
      sx={{
        width: '100%', // Full width
        maxWidth: '100%', // Ensuring it occupies full width
        padding: 2,
        // bgcolor: 'background.paper',
      }}
    >
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/home" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
          หน้าหลัก
        </Link>

        <Link to={`/summary/${staffId}`} style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
          สรุปงาน
        </Link>
        <Typography color="text.primary">รายชื่อ</Typography>
      </Breadcrumbs>
      <Typography
        variant="h5"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 2 }}
      >
        รายชือทั้งหมด
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2, // Adds spacing between the boxes
        }}
      >
        {/* Mapping over sections to create Paper for each */}
        {zoneData ? (
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              boxShadow: 3,
              borderRadius: '20px', // Add border-radius here
            }}
          >
            <Typography variant="body1" fontWeight={700} fontSize={16} sx={{ mb: 1 }}>
              พื้นที่: {zoneData.data.name}
            </Typography>

            {/* List people within the section */}
            {zoneData.data.customers.map((person, personIndex) => {
              return (
                <Accordion
                  key={personIndex}
                  sx={{
                    borderRadius: '10px !important',
                    mb: 1,
                    backgroundColor: getStatusColor(person.surveyResult),
                    color: theme.palette.text.primary,
                    '&:before': {
                      display: 'none',
                    },
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                    <Typography sx={{ fontWeight: 600 }} color="white">
                      {person.displayName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: 'white',
                      color: theme.palette.text.secondary,
                      borderRadius: '0 0 10px 10px',
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Typography fontWeight={600}>เลขบัตร:</Typography>
                        <Typography>{person?.nationalId}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Typography fontWeight={600}>เบอร์โทร:</Typography>
                        <Typography>{person?.tel}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Typography fontWeight={600}>ที่อยู่:</Typography>
                        <Typography>
                          {person?.address || person?.zoneL4 || person?.zoneL3 || person?.zoneL2 || person?.zoneL1
                            ? person?.address || person?.zoneL4 + person?.zoneL3 + person?.zoneL2 + person?.zoneL1
                            : '-'}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Paper>
        ) : (
          <Paper elevation={3} sx={{ padding: 2, boxShadow: 3, borderRadius: '10px' }}>
            <Typography variant="h6" color="text.secondary" align="center">
              ไม่มีข้อมูลพื้นที่สำหรับทีมนี้
            </Typography>
          </Paper>
        )}
      </Box>
      <Button
        variant="outlined"
        fullWidth
        onClick={() => navigate(-1)}
        sx={{
          mt: 3,
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          fontWeight: 600,
          '&:hover': {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
          },
        }}
      >
        ย้อนกลับ
      </Button>
    </Box>
  );
};

export default AllContactSummaryPage;
