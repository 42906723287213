import React, { useEffect, useState } from 'react';
import { Typography, Box, Paper, Button } from '@mui/material';
import theme from '../../../theme/theme';
import { getGifts } from '../../../service/contact-service';

const SummaryAddContactPage = React.memo(({ setActiveStep, selectedPeople, itemCounts }) => {
  const [gifts, setGifts] = useState([]);
  console.log(selectedPeople, 'fffffff');

  useEffect(() => {
    const fetchData = async () => {
      const giftsData = await getGifts();

      if (giftsData && giftsData.data) {
        setGifts(giftsData.data);
      }
    };

    fetchData();
  }, []);

  const handleConfirm = async () => {
    try {
      setActiveStep((prev) => prev + 1);
      // Here you can add logic to send the location data to your server or API
    } catch (error) {
      // Handle the error as needed (e.g., show an error message)
    } finally {
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'HIGH':
        return theme.palette.component.good;
      case 'LOW':
        return theme.palette.component.improve;
      case 'MEDIUM':
        return theme.palette.component.medium;
      default:
        return theme.palette.component.default;
    }
  };

  const getGiftIcon = (iconName) => {
    switch (iconName) {
      case 'apple':
        return '🍎';
      case 'banana':
        return '🍌';
      case 'orange':
        return '🍊';
      case 'grape':
        return '🍇';
      default:
        return '🎁';
    }
  };

  const findGiftIconById = (giftId) => {
    const gift = gifts.find((g) => g.id === parseInt(giftId)); // Parse giftId to ensure type consistency
    return gift ? getGiftIcon(gift.iconName) : '🎁';
  };

  return (
    <Box sx={{ width: '100%', padding: 2, backgroundColor: '#f0f2f5' }}>
      <Typography
        variant="h5"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 2 }}
      >
        เลือกสมาชิก
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Paper elevation={3} sx={{ padding: 2, boxShadow: 3, borderRadius: '20px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {selectedPeople?.length > 0 ? (
              selectedPeople?.map((person, idx) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 2,
                    borderRadius: '10px',
                    boxShadow: 2,
                    mb: 1,
                    backgroundColor: getStatusColor(person.surveyResult),
                  }}
                >
                  <Typography sx={{ flexGrow: 1, fontWeight: 600 }} color="white">
                    {person.displayName}
                  </Typography>

                  {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {person.giftMeta?.map((gift, idx) => (
                      <Typography key={idx} sx={{ fontWeight: 500 }}>
                        {findGiftIconById(gift.id)} {gift.count}
                      </Typography>
                    ))}
                  </Box> */}
                </Box>
              ))
            ) : (
              <Typography>No selected people to display.</Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, my: 5 }}>
            <Typography fontWeight={700}>บริจาคต่อคน:</Typography>
            {itemCounts?.map((gift, idx) => (
              <Typography key={idx} sx={{ fontWeight: 500 }}>
                {findGiftIconById(gift?.id)} {gift?.count / selectedPeople?.length}{' '}
                {/* Display the icon for the gift */}
              </Typography>
            ))}
          </Box>

          <Typography
            variant="h5"
            fontWeight={700}
            align="center"
            gutterBottom
            sx={{ color: theme.palette.primary.main, my: 2 }}
          >
            รายการที่เพิ่ม
          </Typography>

          {gifts.map((item) => {
            const itemCount = itemCounts.find((entry) => entry.id === item.id)?.count || 0;

            return (
              <Box
                key={item.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: '#e0f7fa',
                  padding: 1,
                  borderRadius: 2,
                  mb: 2,
                }}
              >
                <Typography>
                  {getGiftIcon(item?.iconName)} {item?.name}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography
                    sx={{
                      minWidth: '40px',
                      textAlign: 'center',
                      border: '1px solid black',
                      borderRadius: '5px',
                    }}
                  >
                    {itemCount}
                  </Typography>
                </Box>
              </Box>
            );
          })}

          <Box
            display="flex"
            justifyContent="space-between" // Adjusts space between buttons
            sx={{ marginTop: 2 }} // Optional: margin top for spacing above the buttons
          >
            <Button
              variant="outlined"
              type="button" // Changed to "button" to avoid form submission on click
              fullWidth
              onClick={() => {
                setActiveStep((prev) => prev - 1);
              }}
              sx={{
                fontWeight: 700,
                paddingY: 1.5,
                marginRight: 1, // Optional: add margin to space buttons
              }}
            >
              ย้อนกลับ
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              onClick={() => handleConfirm()}
              sx={{
                fontWeight: 700,
                paddingY: 1.5,
                marginLeft: 1, // Optional: add margin to space buttons
              }}
            >
              ยืนยัน
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
});

export default SummaryAddContactPage;
