// UncontactedListPage.js
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Paper,
  Button,
  Breadcrumbs,
  CircularProgress,
  Pagination,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { getZonesBySurveyStatus } from '../../service/contact-service';

const UncontactedListPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [uncontactedList, setUncontactedList] = useState([]);
  const [unmanagedCustomersList, setUnmanagedCustomersList] = useState({});
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  const fetchData = async (page) => {
    setLoading(true);
    const offset = (page - 1) * itemsPerPage;
    try {
      const data = await getZonesBySurveyStatus(false, offset, itemsPerPage);
      if (data && data.data) {
        setUncontactedList(data.data);
        setUnmanagedCustomersList(data?.additionalInfo?.unmanagedCustomers);
        setTotalItems(data.totalCount || 0); // assuming API response includes a `totalCount`
      }
    } catch (error) {
      console.error('Failed to fetch uncontacted list', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page);
  }, [page]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '100%', padding: 3, gap: 3 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/home" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
          หน้าหลัก
        </Link>
        <Typography color="text.primary">รายชื่อคนที่ยังไม่ได้คุย</Typography>
      </Breadcrumbs>
      <Typography
        variant="h5"
        fontWeight={700}
        align="center"
        gutterBottom
        sx={{ color: theme.palette.primary.main, my: 2 }}
      >
        รายชื่อคนที่ยังไม่ได้คุย
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {unmanagedCustomersList?.nonSurveyedCustomers?.length > 0 && (
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                }}
              >
                <Typography variant="body1" fontWeight={700} fontSize={16} sx={{ mb: 1 }}>
                  พื้นที่: ไม่กำหนดพื้นที่
                </Typography>
                {unmanagedCustomersList?.nonSurveyedCustomers?.length > 0 ? (
                  unmanagedCustomersList?.nonSurveyedCustomers?.map((person, personIndex) => (
                    <Accordion
                      key={personIndex}
                      sx={{
                        borderRadius: '10px !important',
                        mb: 1,
                        backgroundColor: theme.palette.component.default,
                        color: theme.palette.text.primary,
                        '&:before': { display: 'none' },
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                        <Typography sx={{ fontWeight: 600 }} color="white">
                          {person.displayName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: 'white',
                          color: theme.palette.text.secondary,
                          borderRadius: '0 0 10px 10px',
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Box sx={{ display: 'flex', gap: 2 }}>
                            <Typography fontWeight={600}>ชื่อ-สกุล:</Typography>
                            <Typography>{person.displayName}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', gap: 2 }}>
                            <Typography fontWeight={600}>ที่อยู่:</Typography>
                            <Typography>{person.address}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', gap: 2 }}>
                            <Typography fontWeight={600}>เลขบัตร:</Typography>
                            <Typography>{person.nationalId}</Typography>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <Typography color="text.secondary" sx={{ fontStyle: 'italic', textAlign: 'center', mt: 2 }}>
                    ไม่มีข้อมูลลูกค้าที่ไม่ได้คุยในพื้นที่นี้
                  </Typography>
                )}
              </Paper>
            )}

            {uncontactedList.map((section, index) => (
              <Paper
                key={index}
                elevation={3}
                sx={{
                  padding: 2,
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                }}
              >
                <Typography variant="body1" fontWeight={700} fontSize={16} sx={{ mb: 1 }}>
                  พื้นที่: {section?.name}
                </Typography>
                {section?.customers?.length > 0 ? (
                  section.customers.map((person, personIndex) => (
                    <Accordion
                      key={personIndex}
                      sx={{
                        borderRadius: '10px !important',
                        mb: 1,
                        backgroundColor: theme.palette.component.default,
                        color: theme.palette.text.primary,
                        '&:before': { display: 'none' },
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                        <Typography sx={{ fontWeight: 600 }} color="white">
                          {person.displayName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: 'white',
                          color: theme.palette.text.secondary,
                          borderRadius: '0 0 10px 10px',
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Box sx={{ display: 'flex', gap: 2 }}>
                            <Typography fontWeight={600}>ชื่อ-สกุล:</Typography>
                            <Typography>{person.displayName}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', gap: 2 }}>
                            <Typography fontWeight={600}>ที่อยู่:</Typography>
                            <Typography>{person.address}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', gap: 2 }}>
                            <Typography fontWeight={600}>เลขบัตร:</Typography>
                            <Typography>{person.nationalId}</Typography>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <Typography color="text.secondary" sx={{ fontStyle: 'italic', textAlign: 'center', mt: 2 }}>
                    ไม่มีข้อมูลลูกค้าที่ไม่ได้คุยในพื้นที่นี้
                  </Typography>
                )}
              </Paper>
            ))}
          </Box>
          <Pagination
            count={Math.ceil(totalItems / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
            sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}
          />
          <Button
            variant="outlined"
            fullWidth
            onClick={() => navigate(-1)}
            sx={{
              mt: 3,
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
              fontWeight: 600,
              '&:hover': {
                borderColor: theme.palette.primary.dark,
                color: theme.palette.primary.dark,
              },
            }}
          >
            ย้อนกลับ
          </Button>
        </>
      )}
    </Box>
  );
};

export default UncontactedListPage;
