import { createTheme } from '@mui/material/styles';

const palette = {
  primary: {
    main: '#5E92F3', // Calmer Blue
    light: '#8AB4F8', // Soft Blue
    dark: '#3A70C9', // Darker Blue
  },
  secondary: {
    main: '#F3A76B', // Soft Orange for contrast
    light: '#F8C18A', // Lighter Orange
    dark: '#C97E3A', // Dark Orange
  },
  error: {
    main: '#E57373', // Mellow Red
    light: '#FF8A80', // Light Red for errors
    dark: '#D32F2F', // Darker Red for strong emphasis
  },
  background: {
    default: '#FAFAFA', // Soft off-white background for a modern look
    paper: '#FFFFFF', // Pure white for card backgrounds
  },
  text: {
    primary: '#333333', // Dark Gray for main text
    secondary: '#666666', // Medium Gray for secondary text
  },
  component: {
    medium: '#ffbd59',
    improve: '#ff5757',
    good: '#9bb138',
    default: '#545454',
  },
};

export default palette;
