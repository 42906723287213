import React, { useEffect, useState } from 'react';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import AddListForContactPage from './add-contect-form-step/add-list-for-contact-page';
import SummaryAddContactPage from './add-contect-form-step/summary-add-contact-page';
import ReviewAddContactPage from './add-contect-form-step/review-add-contact-page';
import OCRScanPage from './add-contect-form-step/ocr-scan-page';
import AddContactingFormPage from './add-contect-form-step/add-contacting-form-page';
import SelectContactPage from './add-contect-form-step/select-contact-page';
import SearchPage from './add-contect-form-step/search-page';
import SearchSelectPage from './add-contect-form-step/search-select-page';
import { useLocation } from 'react-router-dom';
import AddContactingCompletePage from './add-contect-form-step/add-contacting-complete-page';

const steps = ['สแกน', 'กรอก', 'เพิ่ม', 'สรุป', 'รีวิว'];

const AddContactingMainPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [photoData, setPhotoData] = useState(null); // New state to store photo data
  const [addManualContact, setAddManualContact] = useState([]);

  const [formData, setFormData] = useState({
    preName: '',
    name: '',
    surname: '',
    id: '',
    address: '',
    province: '',
    district: '',
    subDistrict: '',
    house: '',
  });
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [itemCounts, setItemCounts] = useState([{ id: null, count: 0 }]);
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false); // State to manage loading status
  const [completeData, setCompleteData] = useState([]);
  const location1 = useLocation();
  const { fromPage, preName, name, surname, id, address, zoneL1, zoneL2, zoneL3, zoneL4 } = location1.state || {};

  useEffect(() => {
    if (fromPage == 'addPage') {
      setActiveStep(2);
      setFormData({
        preName: preName,
        name: name,
        surname: surname,
        id: id,
        address: address,
        province: zoneL1,
        district: zoneL2,
        subDistrict: zoneL3,
        house: zoneL4,
      });
    } else {
      setActiveStep(0);
    }
  }, [fromPage]);

  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <OCRScanPage
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setPhotoData={setPhotoData}
            setFormData={setFormData}
          />
        );
      case 1:
        return (
          <AddContactingFormPage
            setActiveStep={setActiveStep}
            photoData={photoData}
            setFormData={setFormData}
            formData={formData}
          />
        );
      case 2:
        return (
          <SelectContactPage
            setActiveStep={setActiveStep}
            formData={formData}
            setSelectedPeople={setSelectedPeople}
            fromPage={fromPage}
            addManualContact={addManualContact}
          />
        );

      case 3:
        return (
          <AddListForContactPage
            setActiveStep={setActiveStep}
            setSelectedPeople={setSelectedPeople}
            selectedPeople={selectedPeople}
            itemCounts={itemCounts}
            setItemCounts={setItemCounts}
          />
        );
      case 4:
        return (
          <SummaryAddContactPage
            setActiveStep={setActiveStep}
            selectedPeople={selectedPeople}
            itemCounts={itemCounts}
          />
        );
      case 5:
        return (
          <ReviewAddContactPage
            setActiveStep={setActiveStep}
            selectedPeople={selectedPeople}
            location={location}
            itemCounts={itemCounts}
            setLocation={setLocation}
            loading={loading}
            setLoading={setLoading}
            setCompleteData={setCompleteData}
          />
        );
      case 6:
        return <SearchPage setActiveStep={setActiveStep} setFormData={setFormData} />;
      case 7:
        return (
          <SearchSelectPage
            setActiveStep={setActiveStep}
            setAddManualContact={setAddManualContact}
            addManualContact={addManualContact}
          />
        );
      case 8:
        return <AddContactingCompletePage completeData={completeData} itemCounts={itemCounts} />;

      default:
        return <OCRScanPage setActiveStep={setActiveStep} />;
    }
  };

  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      {/* <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper> */}
      <Box sx={{}}>{renderStepContent(activeStep)}</Box>
    </Box>
  );
};

export default AddContactingMainPage;
