import React, { createContext, useContext, useState } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext(null);

export const checkTokenExpired = (token) => {
  if (!token) {
    return { user: null, token: null };
  }

  try {
    const decoded = jwtDecode(token);
    const isExpired = decoded.exp < Math.round(new Date().getTime() / 1000);
    if (isExpired) {
      return { user: null, token: null };
    }
    return { user: decoded, token };
  } catch (err) {
    console.error('Invalid token:', err);
    return { user: null, token: null };
  }
};

const useToken = () => {
  const [session, setSession] = useState(() => {
    const token = localStorage.getItem('token');
    return checkTokenExpired(token);
  });

  const setAccessToken = (token) => {
    const validatedSession = checkTokenExpired(token);
    setSession(validatedSession);
    if (validatedSession.token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  };

  const removeToken = () => {
    setSession({ user: null, token: null });
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    window.location.href = '/login';
  };

  const refreshAccessToken = async (refreshToken) => {
    try {
      // Call refresh API with refresh token
      const response = await refreshToken(refreshToken); // Replace with actual refresh API call
      if (response?.access_token) {
        setAccessToken(response.access_token);
        localStorage.setItem('refresh_token', response.refresh_token || refreshToken); // Update if a new refresh token is provided
        return response.access_token;
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
    return null;
  };

  const checkToken = async () => {
    const { token } = session;
    if (!token) return null;

    const validatedSession = checkTokenExpired(token);
    if (!validatedSession.token) {
      const refreshToken = localStorage.getItem('refresh_token');
      if (refreshToken) {
        return await refreshAccessToken(refreshToken);
      } else {
        removeToken();
        return null;
      }
    }
    return token;
  };

  return {
    user: session.user,
    token: session.token,
    setAccessToken,
    removeToken,
    refreshAccessToken,
    checkToken,
  };
};

export const AuthProvider = ({ children }) => {
  const { user, token, setAccessToken, removeToken, refreshAccessToken, checkToken } = useToken();

  const logout = () => {
    removeToken();
    window.location.href = '/login'; // Redirect to login
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        setAccessToken,
        removeToken,
        refreshAccessToken,
        checkToken,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export default AuthContext;
