import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/'); // Redirect to the home or login page
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        gap: 2,
      }}
    >
      <Box
        component="img"
        src="https://via.placeholder.com/300x300?text=404" // Replace with a custom image URL
        alt="Page not found"
        sx={{
          width: '80%',
          maxWidth: 300,
          mb: 3,
        }}
      />
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Oops! Page Not Found
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        The page you are looking for does not exist or has been moved.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoHome}
        sx={{
          width: '100%',
          maxWidth: 300,
          py: 1.5,
          borderRadius: 2,
          textTransform: 'none',
        }}
      >
        Go Back to Home
      </Button>
    </Container>
  );
};

export default NotFoundPage;
